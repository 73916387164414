import React from "react"

export const remove = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M13 3L3 13M3 3L13 13"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
