import React from "react"

export const check = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12 5L6.5 11L4 8.27273"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
